<template>
  <div class="logo-container">
    <div class="logo-container-image-container">
      <img
        src="/assets/MM_logo.png"
        alt="Mindspace Logo"
        class="logo-container-image-container-image" />
    </div>
    <p v-if="isStartScreen" class="logo-container-text">
      Spend some time <br />
      to pop bubbles <br />
      and breathe slowly
    </p>
    <p v-else class="logo-container-text">
      Well done!<br />
      You just gave yourself <br />a bit of mindspace. <br />
      Enjoy the rest of your day.
    </p>
  </div>
</template>

<script>
export default {
  name: "logoContainer",
  methods: {},
  computed: {
    isStartScreen() {
      return this.$route.path === "/" || this.$route.name === "StartScreen";
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  flex-direction: column;
  padding-top: 22vh;
  padding-bottom: 6vh;
  align-items: center;

  &-text {
    padding-left: 38vw;
    font-size: var(--font-size-body);
    line-height: 1.4;
    text-transform: uppercase;
  }
  &-image-container {
    width: 78%;
    height: 28vw;

    &-image {
      width: 100%;
    }
  }
}
</style>
