<template>
  <div class="end-screen">
    <logoContainer />
    <div class="end-screen-container">
      <p class="end-screen-text">
        Back to work <br />
        or...
      </p>
      <primaryButton @click="$router.push('/')" text="PLAY AGAIN" />
    </div>
  </div>
</template>

<script>
import logoContainer from "../components/organisms/logoContainer.vue";
import primaryButton from "../components/atoms/primaryButton.vue";

export default {
  name: "EndScreen",
  components: { primaryButton, logoContainer },
};
</script>

<style lang="scss" scoped>
.end-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    max-width: 500px;
  }
  &-text {
    text-align: center;
    line-height: 1.6;
    text-transform: uppercase;
  }
}
</style>
