<template>
  <div class="top-buttons">
    <span v-if="$route.path.startsWith('/play/')">
      <p class="top-buttons-count">{{ bubblesPopped }}</p></span
    >
    <div class="top-buttons-icons">
      <iconButton
        :src="src"
        :alt="altTag"
        :ariaLabel="ariaLabel"
        @click="toggleSound" />
      <iconButton
        v-if="$route.path.startsWith('/play/')"
        src="/assets/close.svg"
        alt="Close button"
        ariaLabel="Close button"
        @click="endGame" />
    </div>
  </div>
</template>
<script>
import iconButton from "@/components/atoms/iconButton.vue";

export default {
  name: "topBar",
  props: ["bubblesPopped"],
  emits: ["reset-game"],
  data() {
    return {
      isSoundOn: false,
      soundOnAudio: new Audio("/assets/relaxing_music_by_PiotrWitowski.mp3"),
      volumeLevel: 0.5,
    };
  },
  components: { iconButton },
  computed: {
    altTag() {
      return this.isSoundOn ? "Sound ON button" : "Sound OFF button";
    },
    ariaLabel() {
      return this.isSoundOn ? "Sound ON button" : "Sound OFF button";
    },
    src() {
      return this.isSoundOn ? "/assets/sound-on.svg" : "/assets/sound-off.svg";
    },
  },
  methods: {
    toggleSound() {
      this.isSoundOn = !this.isSoundOn;
      if (this.isSoundOn) {
        this.volumeLevel = 0.5;
        this.soundOnAudio.volume = this.volumeLevel;
        this.soundOnAudio.play();
      } else {
        this.volumeLevel = 0;
        this.soundOnAudio.volume = this.volumeLevel;
        this.soundOnAudio.pause();
      }
    },
    endGame() {
      // Emit event for the bubble count to be reset and go to the end screen
      // Added timeout so the score would refresh after page transition is done
      setTimeout(() => {
        this.$emit("reset-game");
      }, 1000);

      this.$router.push("/end");
    },
  },
};
</script>

<style lang="scss">
.top-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 2rem;

  &-icons {
    position: fixed;
    right: 7%;
    display: flex;
    gap: 0.5rem;
    z-index: 100;
  }
  &-count {
    position: fixed;
    top: 4%;
    left: 7%;
    font-size: 19px;
  }
}
</style>
