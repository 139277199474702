<template>
  <div
    class="single-bubble"
    :style="'width:' + size + 'vh;height:' + size + 'vh'"
    @click="$emit('bubble-clicked')"></div>
</template>

<script>
export default {
  name: "bubbleComponent",
  props: { size: Number },
  methods: {},
};
</script>

<style lang="scss">
.single-bubble {
  // Width and height defined by the size prop
  background-color: none;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 10px rgb(254, 246, 246) inset;
  z-index: 1;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.single-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
  background: -webkit-linear-gradient(
    to right,
    rgb(253, 146, 146),
    rgb(254, 254, 171),
    rgb(174, 254, 174),
    rgb(253, 179, 253),
    rgb(255, 242, 129)
  ); /* Chrome  10-25, Safari  5.1-6 */
  background: linear-gradient(
    to right,
    rgb(253, 146, 146),
    rgb(254, 254, 171),
    rgb(174, 254, 174),
    rgb(253, 179, 253),
    rgb(255, 255, 134)
  ); /* W3C, IE  10+/ Edge, Firefox  16+, Chrome  26+, Opera  12+, Safari  7+ */
  opacity: 0.09;
}
</style>
