<template>
  <div class="button-container">
    <div class="button-container-radio">
      <radioButton
        text="3 min"
        value="3"
        v-model="selectedDuration"
        name="duration" />
      <radioButton
        text="5 min"
        value="5"
        v-model="selectedDuration"
        name="duration" />
    </div>
    <primaryButton @click="startGame(selectedDuration)" text="START GAME" />
  </div>
</template>

<script>
import radioButton from "../atoms/radioButton.vue";
import primaryButton from "../atoms/primaryButton.vue";

export default {
  name: "startButtons",
  components: { primaryButton, radioButton },
  data() {
    return {
      selectedDuration: "3",
    };
  },
  methods: {
    startGame(duration) {
      this.$router.push(`/play/${duration}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4vh;
  width: 70%;
  max-width: 500px;
  gap: 1em;

  &-radio {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }
}
</style>
