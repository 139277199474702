<template>
  <img class="icon" :src="src" :alt="alt" />
</template>

<script>
export default {
  name: "iconComponent",
  props: {
    alt: String,
    src: String,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  stroke: var(--primary-white);
  fill: var(--primary-white);
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
}
</style>
