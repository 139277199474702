<template>
  <div>
    <div v-if="screenWidth > 768" class="desktop-message">
      <p>To enter the Manyone Mindspace open this site from a mobile device</p>
      <img src="/assets/qr-code.png" alt="Mindspace logo" />
    </div>
    <div v-else>
      <topBar :bubblesPopped="bubblesPopped" @reset-game="resetGame" />
      <router-view @update-bubbles-popped="updateBubblesPopped"></router-view>
    </div>
  </div>
</template>

<script>
import topBar from "./components/organisms/topBar.vue";

export default {
  name: "App",
  components: { topBar },
  data() {
    return {
      bubblesPopped: 0,
      screenWidth: window.innerWidth,
    };
  },
  methods: {
    updateBubblesPopped(count) {
      // Update the bubblesPopped count in the topBar
      this.bubblesPopped = count;
    },
    resetGame() {
      this.bubblesPopped = 0;
    },
    updateScreenWidth() {
      // Check screenwidth to display the desktop message
      this.screenWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-white);
  height: 100%;
  padding-left: 7%;
  padding-right: 7%;
}
.desktop-message {
  position: fixed;
  top: 32%;
  width: 86%;
  text-align: center;
  color: var(--primary-white);

  & p {
    font-size: 25px;
    line-height: normal;
  }
  & img {
    margin-top: 4rem;
    width: 100%;
    max-width: 200px;
    height: auto;
  }
}
</style>
