<template>
  <div class="timer-container">
    <div class="timer-container-row">
      <img src="/assets/MM_logo_small.png" alt="Mindspace logo" />
      <p class="timer-container-time">{{ formattedTimeLeft() }}</p>
    </div>
    <progressBar
      :totalDuration="totalDuration"
      :remainingTime="remainingTime" />
  </div>
</template>

<script>
import progressBar from "../atoms/progressBar.vue";

export default {
  name: "PlayScreen",
  components: {
    progressBar,
  },
  props: ["duration"],
  data() {
    return {
      remainingTime: 0,
      timer: null,
    };
  },
  computed: {
    totalDuration() {
      return this.duration * 60; // Convert minutes to seconds
    },
  },
  created() {
    this.remainingTime = this.totalDuration;
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.remainingTime--;
        if (this.remainingTime <= 0) {
          clearInterval(this.timer);
          this.bubblesPopped = 0; // Reset the bubble count and emit the event that count has updated
          this.$emit("update-bubbles-popped", this.bubblesPopped);
          this.$router.push(`/end`);
        }
      }, 1000);
    },
    formattedTimeLeft() {
      const timeLeft = this.remainingTime;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.timer-container {
  z-index: 100;
  width: 87%;
  position: fixed;
  bottom: 0;

  &-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  & img {
    width: 50%;
    max-width: 200px;
    height: auto;
  }
  &-time {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1rem;
    color: var(--primary-white);
  }
}
</style>
