<template>
  <div class="progress-bar">
    <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: "progressBar",
  props: {
    totalDuration: {
      type: Number,
      required: true,
    },
    remainingTime: {
      type: Number,
      required: true,
    },
  },
  computed: {
    progressPercentage() {
      return (
        ((this.totalDuration - this.remainingTime) / this.totalDuration) * 100
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 12px;
  margin-bottom: 2rem;
  background-color: RGBA(239, 239, 240, 0.3);
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}
.progress {
  position: absolute;
  height: 100%;
  min-width: 2px;
  background: #c8e6c3;
  background: -webkit-linear-gradient(
    to right,
    #f4d0d6,
    #efe0bd,
    #c8e6c3,
    #c5becc,
    #efe0bd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f4d0d6,
    #efe0bd,
    #c8e6c3,
    #c5becc,
    #efe0bd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 25px;
  transition: width 1s linear;
}
</style>
