<template>
  <div class="play-screen">
    <div class="play-screen-bubble-container" ref="bubbleContainer">
      <bubble
        v-for="(size, index) in bubbleSizes"
        :key="index"
        :size="size"
        :style="{ left: bubblePositions[index] + 'px' }"
        class="bubble"
        :data-index="index"
        @bubble-clicked="handleBubbleClick(index)" />
    </div>
    <timerContainer :duration="duration" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import bubble from "../components/atoms/bubble.vue";
import timerContainer from "../components/organisms/timerContainer.vue";

export default {
  name: "PlayScreen",
  components: {
    bubble,
    timerContainer,
  },
  props: ["duration"],
  data() {
    return {
      bubbleSizes: [],
      bubblePositions: [],
      bubblesPopped: 0,
    };
  },
  created() {
    this.generateBubbleSizes();
  },
  mounted() {
    this.generateBubblePositions();
    this.initAnimation();
  },
  beforeUnmount() {
    if (this.animation) {
      this.animation.kill();
    }
  },
  methods: {
    generateBubbleSizes() {
      const minBubbleSize = 10;

      // Create an array with random sizes for each bubble
      this.bubbleSizes = Array.from({ length: 20 }, () => {
        let bubbleSize =
          Math.floor(Math.random() * (20 - minBubbleSize)) + minBubbleSize;
        return bubbleSize;
      });
    },
    generateBubblePositions() {
      // Create an array with random left positions for each bubble depending on screen width
      const containerWidth = this.$refs.bubbleContainer.offsetWidth;
      const minPosition = -5;
      const maxPosition = containerWidth - 100;

      this.bubblePositions = Array.from({ length: 20 }, () => {
        let bubblePosition =
          Math.floor(Math.random() * (maxPosition - minPosition + 1)) +
          minPosition;
        return bubblePosition;
      });
    },
    handleBubbleClick(index) {
      const bubbleElement = this.$refs.bubbleContainer.querySelector(
        `.bubble[data-index="${index}"]`
      );

      // Emit an event to update bubble count
      this.bubblesPopped++;
      this.$emit("update-bubbles-popped", this.bubblesPopped);

      // Animation for the bubble when it's clicked
      // First, scale the bubble a bit bigger, then back to normal size and fade out
      if (bubbleElement) {
        gsap.to(bubbleElement, {
          duration: 0.1,
          scale: 1.2,
          ease: "power1.inOut",
          repeat: 1,
          yoyo: true,
          onComplete: () => {
            gsap.to(bubbleElement, {
              duration: 0.1,
              opacity: 0,
              pointerEvents: "none",
            });
          },
        });
      }
    },
    handleBubbleVisibility() {
      const targets = document.querySelectorAll(
        ".play-screen-bubble-container .bubble"
      );
      const container = document.querySelector(".play-screen-bubble-container");

      targets.forEach((bubble) => {
        const bubbleRect = bubble.getBoundingClientRect();

        // When bubble reaches the top set opacity back to 0
        if (bubbleRect.bottom <= container.offsetTop) {
          bubble.style.opacity = "1";
          bubble.style.pointerEvents = "all";
        }
      });
    },
    initAnimation() {
      const targets = document.querySelectorAll(
        ".play-screen-bubble-container .bubble"
      );
      const numberOfTargets = targets.length;
      const duration = 25;
      const stagger = 2;

      // Animate bubbles moving up
      // To achieve perfect loop, have to set the repeatDelay to each bubble instead of the whole animation
      // This way we ensure that each bubble repeats after repeatDelay amount of time and there is no empty screen when all bubbles in the array have animated
      const repeatDelay = stagger * (numberOfTargets - 12);

      this.animation = gsap
        .timeline({
          repeat: 2,
          onUpdate: () => {
            // Check when the bubbles reach the top of the screen (for setting the opacity back to 1)
            this.handleBubbleVisibility();
          },
        })
        .from(targets, {
          y: 80,
          duration: duration,
          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay: repeatDelay,
          },
        })
        .to(
          targets,
          {
            y: -1200,
            duration: duration,
            stagger: {
              each: stagger,
              repeat: -1,
              repeatDelay: repeatDelay,
            },
          },
          stagger
        );

      // Animate bubbles slowly moving from left to right
      targets.forEach((bubble) => {
        // Generate a random range for the x position, so each bubble moves a bit differently
        const xPosition = gsap.utils.random(-40, 40);
        const swingDuration = gsap.utils.random(2, 5);

        gsap.to(bubble, {
          x: xPosition,
          duration: swingDuration,
          ease: "power1.inOut",
          repeat: -1,
          yoyo: true,
        });
      });

      // Morph the bubbles
      targets.forEach((bubble) => {
        // Generate a random duration and rotation, so each bubble moves a bit differently
        const duration = gsap.utils.random(1, 4);
        const rotation = gsap.utils.random(60, 120);

        gsap.to(bubble, {
          duration: duration,
          scaleX: 1.03,
          scaleY: 1.03,
          skewX: "2deg",
          skewY: "2deg",
          rotate: rotation,
          ease: "power1.inOut",
          repeat: -1,
          yoyo: true,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.play-screen {
  position: relative;
  min-height: 100vh;

  &-bubble-container {
    position: relative;
    width: 100%;
    height: 120vh;
  }
}
</style>
