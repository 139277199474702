<template>
  <div class="start-screen">
    <logoContainer />
    <startButtons />
  </div>
</template>

<script>
import logoContainer from "../components/organisms/logoContainer.vue";
import startButtons from "../components/organisms/startButtons.vue";

export default {
  name: "StartScreen",
  components: { logoContainer, startButtons },
};
</script>

<style lang="scss" scoped>
.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
