<template>
  <button :text="text" class="primary-button">{{ text }}</button>
</template>

<script>
export default {
  name: "primaryButton",
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.primary-button {
  font-size: var(--font-size-buttons);
  padding: 1.5rem 2rem;
  margin-top: 0.7rem;
  width: 100%;
  border: var(--primary-white) 1px solid;
  border-radius: 3rem;
  background-color: transparent;
  color: var(--primary-white);
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
}
</style>
