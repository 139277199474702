import { createRouter, createWebHistory } from "vue-router";
import StartScreen from "@/views/StartScreen.vue";
import PlayScreen from "@/views/PlayScreen.vue";
import EndScreen from "@/views/EndScreen.vue";
import gsap from "gsap";

const routes = [
  { path: "/", component: StartScreen },
  { path: "/play/:duration", component: PlayScreen, props: true },
  { path: "/end", component: EndScreen },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Add a fade-out animation
  gsap.to("body", {
    duration: 1,
    opacity: 0,
    onComplete: () => {
      next();
    },
  });
});

router.afterEach(() => {
  document.body.style.opacity = 1;
  // Add a fade-in animation
  gsap.from("body", {
    duration: 1,
    opacity: 0,
  });
});

export default router;
