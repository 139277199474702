<template>
  <button :ariaLabel="ariaLabel" class="icon-button">
    <icon :src="src" :alt="alt" />
  </button>
</template>

<script>
import icon from "./icon.vue";

export default {
  name: "iconButton",
  props: {
    alt: String,
    src: String,
    ariaLabel: String,
  },
  components: { icon },
};
</script>

<style lang="scss" scoped>
.icon-button {
  padding: 0.7rem 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--primary-white) 1px solid;
  border-radius: 5rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
}
</style>
