<template>
  <label class="radio-button" :class="{ checked: isChecked }">
    <span class="radio-button-input">
      <img
        class="button-container-time"
        src="/assets/clock.svg"
        alt="Mindspace logo" />
      <input
        type="radio"
        :value="value"
        :text="text"
        :name="name"
        :checked="modelValue === value"
        @change="updateValue" />
      <span class="radio-button-text">{{ text }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: "radioButton",
  props: {
    value: String,
    text: String,
    modelValue: String,
    name: String,
  },
  data() {
    return {
      isChecked: this.modelValue === this.value,
    };
  },
  emits: ["update:modelValue"],
  methods: {
    updateValue() {
      // Emit an event with the current value for the radioButton component to the parent (startButtons)
      this.$emit("update:modelValue", this.value);
      // Update isChecked for styling purposes
      this.isChecked = true;
    },
  },
  watch: {
    modelValue(newVal) {
      this.isChecked = newVal === this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  cursor: pointer;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: auto;
  width: 100%;
  background-color: RGBA(239, 239, 240, 0.1);
  border-radius: 5rem;

  & input {
    display: none;
  }
}
.radio-button-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.radio-button.checked {
  background-color: RGBA(239, 239, 240, 0.3);
}
.button-container-time {
  width: 18px;
  height: 18px;
  padding-right: 4px;
}
</style>
